import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { Button } from 'gatsby-theme-material-ui';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FadeIn from '../transitions/FadeIn';

const useStyles = makeStyles({
    card: {
        height: '100%',
    },
    button: {
        textAlign: 'center'
    }
});

const ProductCard = ({ title, subTitle, linkText, link, paletteColor, points }) => {
    const classes = useStyles();

    const ButtonComponent = withStyles((theme) => ({
        root: {
            borderColor: theme.palette[paletteColor].dark,
            color: theme.palette[paletteColor].dark,
            '&:hover': {
                borderColor: theme.palette[paletteColor].main,
                color: theme.palette[paletteColor].main,
            },
        },
      }))(Button);

    return (
        <Card className={classes.card} variant="outlined">
            <Box className={classes.card} display="flex" flexDirection="column" justifyContent="space-between">
                <CardContent>
                    <Box color={`${paletteColor}.dark`}>
                        <Box pt={1}>
                            <Typography 
                                align="center"
                                color="inherit"
                                variant="h5"
                                component="h2"
                            >
                                <strong>{ title }</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <Box p={2}>
                                <Divider />
                            </Box>
                            <Box pt={1}>
                                <Typography
                                    align="center"
                                    display="block"
                                    variant="caption"
                                >
                                    {subTitle}
                                </Typography>
                            </Box>
                            <Box p={2}>
                                <Divider />
                            </Box>
                        </Box>
                        <List>
                            {
                                points.map(({ icon, secondaryText, text }) => (
                                    <FadeIn key={text}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                { icon }
                                            </ListItemAvatar>
                                            <ListItemText size="large" primary={text} secondary={secondaryText} />
                                        </ListItem>
                                    </FadeIn>
                                ))
                            }
                    </List>
                    </Box>
                </CardContent>
                <Box pr={2} pb={2} pl={2}>
                    <FadeIn>
                        <ButtonComponent to={link} fullWidth variant="outlined" color="primary" className={classes.button}>
                            {linkText ? linkText : 'Learn More'}
                        </ButtonComponent>
                    </FadeIn>
                </Box>
            </Box>
        </Card>
    );
};

export default ProductCard;
