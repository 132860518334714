import React from 'react';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import CardMembership from '@material-ui/icons/CardMembership';
import DirectionsBoat from '@material-ui/icons/DirectionsBoat';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Event from '@material-ui/icons/Event';
import Forum from '@material-ui/icons/Forum';
import Grid from '@material-ui/core/Grid';
import ListAlt from '@material-ui/icons/ListAlt';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Reorder from '@material-ui/icons/Reorder';
import SettingsInputComposite from '@material-ui/icons/SettingsInputComposite';
import Storefront from '@material-ui/icons/Storefront';

import ProductCard from './ProductCard';

const ProductCards = () => (
    <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={4}>
            <ProductCard
                title="Boat Rental Software"
                subTitle="An online boat rental portal and app to manage reservations."
                link="/products/boat-rental"
                linkText="View Boat Rental Software"
                paletteColor="boatRental"
                points={[
                    {
                        icon: <Event />,
                        text: 'Accept Reservations Online',
                        secondaryText: 'From a customized online portal or directly on your website',
                    },
                    {
                        icon: <ListAlt />,
                        text: 'Customizable inspection forms',
                        secondaryText: 'For damage documentation, checklists, and customer signatures',
                    },
                    {
                        icon: <AttachMoney />,
                        text: 'Dynamic Pricing',
                        secondaryText: 'Control seasonal and holiday pricing with ease',
                    },
                    {
                        icon: <MonetizationOn />,
                        text: 'Damage Deposits',
                        secondaryText: 'Damage deposits can be authorized either automatically or manually',
                    },
                ]}
            />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
            <ProductCard
                title="Boat Club Software"
                subTitle="An app to manage boat club reservation scheduling. Convenient for customers and staff."
                link="/products/boat-club"
                linkText="View Boat Club Software"
                paletteColor="boatClub"
                points={[
                    {
                        icon: <Event />,
                        text: 'Schedule boat reservations',
                        secondaryText: 'From any device using a web browser or the Boat Club App',
                    },
                    {
                        icon: <ListAlt />,
                        text: 'Customizable inspection forms',
                        secondaryText: 'For damage documentation, checklists, and customer signatures',
                    },
                    {
                        icon: <LocalOfferIcon />,
                        text: 'Manage inventory rentals',
                        secondaryText: 'Let customers reserve inventory along with their boat reservations',
                    },
                    {
                        icon: <CardMembership />,
                        text: 'Customize membership plans',
                        secondaryText: 'To match the rules of your club',
                    },
                ]}
            />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
            <ProductCard
                title="Dry Stack Operations"
                subTitle="An app to simplify dry stack marina operations while providing convenience for customers."
                link="/products/dry-stack"
                paletteColor="dryStack"
                linkText="View Dry Stack Marina Software"
                points={[
                    {
                        icon: <DirectionsBoat />,
                        text: 'Schedule launches',
                        secondaryText: 'From any device using a web browser or the SpeedyDock app',
                    },
                    {
                        icon: <AssignmentTurnedIn />,
                        text: 'Handle retrievals',
                        secondaryText: 'Know which boats are out and where they are',
                    },
                    {
                        icon: <Reorder />,
                        text: 'Manage dry stack storage',
                        secondaryText: 'For a real-time visual view of storage',
                    },
                    {
                        icon: <Forum />,
                        text: 'Communicate with customers & staff',
                        secondaryText: 'With text and email status updates',
                    },
                ]}
            />
        </Grid>
    </Grid>
);

export default ProductCards;
